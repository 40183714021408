export {};

const setObj = function(key: string, obj: object): void {
    return this.setItem(key, JSON.stringify(obj));
};

const getObj = function(key: string): object {
    return JSON.parse(this.getItem(key));
};

Storage.prototype.setObj = setObj;
Storage.prototype.getObj = getObj;
