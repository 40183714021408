import {Component} from '@angular/core';
import {FuseSharedModule} from '@fuse/shared.module';
import {VersionChangeLogComponent} from '@core/components/version-change-log/version-change-log.component';
import {TranslateModule} from '@ngx-translate/core';
import {CHANGE_LOG} from '@core/components/version-change-log/version-change-log';
import {FlexModule} from '@ngbracket/ngx-layout';

@Component({
    selector: 'omt-help-panel',
    templateUrl: './help-panel.component.html',
    styleUrls: ['./help-panel.component.scss'],
    imports: [
        FlexModule,
        FuseSharedModule,
        VersionChangeLogComponent,
        TranslateModule
    ],
    standalone: true
})
export class HelpPanelComponent {
    changeLog = CHANGE_LOG;
}
