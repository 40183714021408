import {endOfWeek, startOfWeek} from 'date-fns';

export {};

function withoutTime(this: Date): Date {
    const newDate = new Date(this);
    newDate.setHours(0, 0, 0, 0);
    return newDate;
}

function getMonthStartAndEnd(this: Date): { start: Date; end: Date } {
    const firstDayOfMonth = new Date(this.getFullYear(), this.getMonth(), 1);
    const lastDayOfMonth = new Date(this.getFullYear(), this.getMonth() + 1, 0);
    return {start: firstDayOfMonth, end: lastDayOfMonth};
}

function getWeekStartAndEnd(this: Date): { start: Date; end: Date } {
    const start = startOfWeek(this, {weekStartsOn: 1});
    const end = endOfWeek(this, {weekStartsOn: 1});
    return {start, end};
}

/**
 * Returns the date without time component.
 */
Date.prototype.withoutTime = withoutTime;

Date.prototype.getMonthStartAndEnd = getMonthStartAndEnd;

Date.prototype.getWeekStartAndEnd = getWeekStartAndEnd;
