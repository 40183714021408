import {Component} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatLegacyButtonModule} from '@angular/material/legacy-button';
import {FlexModule} from '@ngbracket/ngx-layout';
import {CdkConnectedOverlay, CdkOverlayOrigin} from '@angular/cdk/overlay';
import {FavoriteListComponent} from '@core/components/favorites/favorite-list.component';
import {FavoriteService} from '@core/services/global/favorite.service';
import {SearchInputModule} from '@libs/ui/src';
import {MatLegacyCardModule} from '@angular/material/legacy-card';
import {map, switchMap} from 'rxjs/operators';
import {MatLegacyTooltipModule} from '@angular/material/legacy-tooltip';
import {TranslateModule} from '@ngx-translate/core';
import {FavoriteType} from '@core/models/favorite.model';
import {BehaviorSubject, combineLatest} from 'rxjs';
import {stringIsNullOrWhiteSpace} from '@core/utils/string-utils';

@Component({
    selector: 'omt-favorite-panel',
    standalone: true,
    imports: [CommonModule, MatLegacyButtonModule, FlexModule, CdkConnectedOverlay, FavoriteListComponent, CdkOverlayOrigin, SearchInputModule, MatLegacyCardModule, MatLegacyTooltipModule, TranslateModule],
    templateUrl: './favorite-panel.component.html',
    styleUrls: ['./favorite-panel.component.scss']
})
export class FavoritePanelComponent {
    searchFilter = new BehaviorSubject('');
    showType = new BehaviorSubject<FavoriteType | null>(null);
    hasSearch$ = this.searchFilter.pipe(map((filter) => !stringIsNullOrWhiteSpace(filter)));
    isOpen = false;

    favorites$ = combineLatest([this.searchFilter, this.showType])
        .pipe(
            switchMap(([filter, type]) => this.favoriteService.favorites$
                .pipe(map((favorites) => favorites
                    .filter((fav) => type ? fav.type === type : true)
                    .filter((fav) =>
                        [fav.board?.name, fav.article?.name, fav.article?.key, fav.ticket?.key, fav.ticket?.name, fav.customer?.name, fav.customer?.key, fav.project?.key, fav.project?.name, fav.wikiArticle?.name]
                            .filter((x) => x != null)
                            .some((x) => x.toLowerCase().includes(filter.toLowerCase()))
                    )
                )))
        );

    constructor(private readonly favoriteService: FavoriteService) {
    }

    filter(filter: string): void {
        this.searchFilter.next(filter);
    }

    filterType(type: FavoriteType | null): void {
        this.showType.next(type);
    }
}
