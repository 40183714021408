import {NgModule} from '@angular/core';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {MatIconModule} from '@angular/material/icon';

import {FuseNavigationModule} from '@fuse/components';
import {FuseSharedModule} from '@fuse/shared.module';
import {NavbarHorizontalStyle1Component} from '@core/layouts/components/navbar/horizontal/style-1/style-1.component';

@NgModule({
    declarations: [NavbarHorizontalStyle1Component],
    imports: [MatButtonModule, MatIconModule, FuseSharedModule, FuseNavigationModule],
    exports: [NavbarHorizontalStyle1Component]
})
export class NavbarHorizontalStyle1Module {
}
