@if (changelog) {
  <div class="version-change-log">
    @for (version of changelog.versions; track version.tag) {
      <div class="version mb-3">
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <div fxLayout="row">
            <i class="fas fa-code-branch"></i>
            <h2 class="version-tag">{{ version.tag }}</h2>
          </div>
          @if (version.releaseDate) {
            <h2 class="version-tag">{{ version.releaseDate }}</h2>
          }
        </div>
        @if (version.added.length) {
          <div class="version-change">
            <div class="version-change-type badge badge-success">{{ 'RELEASE_NOTES.ADDED' | translate }}</div>
            <ul class="version-change-list mb-1">
              @for (change of version.added; track change) {
                <li class="version-change-info mb-0">
                  <i class="fas fa-code-commit"></i>
                  {{ change.translationKey ? ((version.tag + '.' + change.translationKey) | translate) : change.info }}
                </li>
              }
            </ul>
          </div>
        }
        @if (version.improved.length) {
          <div class="version-change">
            <div class="version-change-type badge badge-info">{{ 'RELEASE_NOTES.IMPROVED' | translate }}</div>
            <ul class="version-change-list mb-1">
              @for (change of version.improved; track change) {
                <li class="version-change-info mb-0">
                  <i class="fas fa-code-commit"></i>
                  {{ change.translationKey ? ((version.tag + '.' + change.translationKey) | translate) : change.info }}
                </li>
              }
            </ul>
          </div>
        }
        @if (version.fixed.length) {
          <div class="version-change">
            <div class="version-change-type badge badge-secondary">{{ 'RELEASE_NOTES.FIXED' | translate }}</div>
            <ul class="version-change-list mb-1">
              @for (change of version.fixed; track change) {
                <li class="version-change-info mb-0">
                  <i class="fas fa-code-commit"></i>
                  {{ change.translationKey ? ((version.tag + '.' + change.translationKey) | translate) : change.info }}
                </li>
              }
            </ul>
          </div>
        }
        @if (version.removed.length) {
          <div class="version-change">
            <div class="version-change-type badge badge-danger">{{ 'RELEASE_NOTES.REMOVED' | translate }}</div>
            <ul class="version-change-list mb-1">
              @for (change of version.removed; track change) {
                <li class="version-change-info mb-0">
                  <i class="fas fa-code-commit"></i>
                  {{ change.translationKey ? ((version.tag + '.' + change.translationKey) | translate) : change.info }}
                </li>
              }
            </ul>
          </div>
        }
      </div>
    }
  </div>
}
