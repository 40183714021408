<div class="favorite-panel-wrapper">
  <button mat-icon-button (click)="isOpen = !isOpen" cdkOverlayOrigin #trigger="cdkOverlayOrigin" [matTooltip]="'GLOBAL.FAVORITES' | translate">
    <i class="fas fa-star toolbar-icon"></i>
  </button>
</div>

<ng-template cdkConnectedOverlay [cdkConnectedOverlayOrigin]="trigger" [cdkConnectedOverlayOpen]="isOpen" (overlayOutsideClick)="isOpen = false" cdkConnectedOverlayPush>
  <mat-card class="favorite-panel p-0 mat-elevation-z18">
    <div class="p-1">
      <h2 class="mb-1">{{ 'GLOBAL.FAVORITES' | translate }}</h2>
      <omt-search-input displayMode="full" (searchChanged)="filter($event)"></omt-search-input>
    </div>
    <omt-favorite-list [favorites]="favorites$ | async" [hasSearch]="hasSearch$ | async"></omt-favorite-list>
  </mat-card>
</ng-template>
