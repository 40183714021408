import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {FuseConfigService} from '@fuse/services/config.service';
import {AuthenticationService} from '@core/services/global/authentication.service';
import {MobileService} from '@core/services/global/mobile.service';
import {SubSink} from 'subsink';

@Component({
    selector: 'vertical-layout-1',
    templateUrl: './layout-1.component.html',
    styleUrls: ['./layout-1.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class VerticalLayout1Component implements OnInit, OnDestroy {
    fuseConfig: any;
    navigation: any;
    chatEnabled = false;
    chatError = false;
    private subs = new SubSink();

    constructor(
        public authService: AuthenticationService,
        private readonly _fuseConfigService: FuseConfigService,
        private readonly mobileService: MobileService) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Subscribe to config changes
        this.subs.sink = this._fuseConfigService.config.subscribe((config) => {
            this.fuseConfig = config;
        });

        this.subs.sink = this.authService.currentUserSubject.subscribe(() => this.setChatState());
    }

    private setChatState(): void {
        this.chatEnabled = this.authService.currentUser?.userRoles?.chat && !this.mobileService.mobile;
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    onChatError(): void {
        this.chatError = true;
    }
}
