import {Changelog, Version} from '@core/components/version-change-log/version-change-log.component';

const VERSION_1_5_0: Version = {
    tag: '1.5.0',
    releaseDate: null,
    fixed: [
        {translationKey: '2'},
        {translationKey: '7'},
    ],
    improved: [
        {translationKey: '3'},
        {translationKey: '8'},
    ],
    added: [
        {translationKey: '1'},
        {translationKey: '4'},
        {translationKey: '5'},
        {translationKey: '6'},
    ],
    removed: []
};

const VERSION_1_4_0: Version = {
    tag: '1.4.0',
    releaseDate: null,
    fixed: [
        {translationKey: '1'},
        {translationKey: '2'},
    ],
    improved: [
        {translationKey: '3'},
        {translationKey: '4'},
        {translationKey: '5'},
        {translationKey: '6'},
    ],
    added: [
        {translationKey: '7'},
        {translationKey: '8'},
        {translationKey: '9'},
    ],
    removed: []
};

const VERSION_1_3_1: Version = {
    tag: '1.3.1',
    releaseDate: null,
    fixed: [
        {translationKey: '1'},
        {translationKey: '2'},
    ],
    improved: [],
    added: [
        {translationKey: '3'},
    ],
    removed: []
};

const VERSION_1_3_0: Version = {
    tag: '1.3.0',
    releaseDate: null,
    fixed: [
        {translationKey: '1'},
        {translationKey: '2'},
    ],
    improved: [
        {translationKey: '3'},
        {translationKey: '4'},
    ],
    added: [
        {translationKey: '5'},
        {translationKey: '6'},
        {translationKey: '7'},
        {translationKey: '8'},
    ],
    removed: []
};

const VERSION_1_2_0: Version = {
    tag: '1.2.0',
    releaseDate: null,
    fixed: [
        {translationKey: '34'},
        {translationKey: '29'},
        {translationKey: '28'},
        {translationKey: '27'},
        {translationKey: '24'},
        {translationKey: '9'},
        {translationKey: '5'},
        {translationKey: '2'},
    ],
    improved: [
        {translationKey: '33'},
        {translationKey: '26'},
        {translationKey: '25'},
        {translationKey: '21'},
        {translationKey: '19'},
        {translationKey: '16'},
        {translationKey: '15'},
        {translationKey: '14'},
        {translationKey: '12'},
        {translationKey: '11'},
        {translationKey: '10'},
        {translationKey: '8'},
        {translationKey: '6'},
        {translationKey: '4'},
    ],
    added: [
        {translationKey: '32'},
        {translationKey: '31'},
        {translationKey: '30'},
        {translationKey: '23'},
        {translationKey: '22'},
        {translationKey: '20'},
        {translationKey: '18'},
        {translationKey: '17'},
        {translationKey: '13'},
        {translationKey: '7'},
        {translationKey: '3'},
        {translationKey: '1'}
    ],
    removed: []
};


const VERSION_1_0_1: Version = {
    tag: '1.0.1',
    releaseDate: null,
    fixed: [
        {translationKey: '10'},
        {translationKey: '9'},
        {translationKey: '8'},
        {translationKey: '7'},
        {translationKey: '6'},
        {translationKey: '5'},
        {translationKey: '4'},
        {translationKey: '3'},
        {translationKey: '2'}
    ],
    improved: [
        {translationKey: '1'}
    ],
    added: [],
    removed: []
};


const VERSION_1_1_0: Version = {
    tag: '1.1.0',
    releaseDate: null,
    improved: [
        {translationKey: '35'},
        {translationKey: '34'},
        {translationKey: '33'},
        {translationKey: '28'},
        {translationKey: '27'},
        {translationKey: '26'},
        {translationKey: '24'},
        {translationKey: '22'},
        {translationKey: '19'},
        {translationKey: '16'},
        {translationKey: '13'},
        {translationKey: '10'},
        {translationKey: '6'},
        {translationKey: '3'},
        {translationKey: '2'},
        {translationKey: '1'}
    ],
    added: [
        {translationKey: '32'},
        {translationKey: '31'},
        {translationKey: '30'},
        {translationKey: '25'},
        {translationKey: '23'},
        {translationKey: '20'},
        {translationKey: '17'},
        {translationKey: '14'},
        {translationKey: '12'},
        {translationKey: '11'},
    ],
    fixed: [
        {translationKey: '29'},
        {translationKey: '21'},
        {translationKey: '18'},
        {translationKey: '15'},
        {translationKey: '8'},
        {translationKey: '7'},
        {translationKey: '5'},
        {translationKey: '4'},
    ],
    removed: [
        {translationKey: '9'}
    ]
};

export const CHANGE_LOG: Changelog = {
    versions: [
        VERSION_1_5_0,
        VERSION_1_4_0,
        VERSION_1_3_1,
        VERSION_1_3_0,
        VERSION_1_2_0,
        VERSION_1_1_0,
        VERSION_1_0_1
    ]
};

