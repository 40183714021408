import {Component, Input} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FavoriteModel, FavoriteType} from '@core/models/favorite.model';
import {CoreModule} from '@core/core.module';
import {RouterLink} from '@angular/router';
import {MatDividerModule} from '@angular/material/divider';
import {FlexModule} from '@ngbracket/ngx-layout';
import {NumberRangeKeyModule} from '@libs/ui/src/components/number-range-key/number-range-key.module';
import {TicketTypeModule} from '@ticket/ticket-type/ticket-type.module';
import {AvatarModule} from '@libs/ui/src';
import {FavoriteButtonComponent, FavoriteTypeMap, SupportedFavoriteTypes} from '@core/components/favorites/favorite-button.component';
import {TranslateModule} from '@ngx-translate/core';
import {MissingEntriesInfoModule} from '@core/components/missing-entries-info/missing-entries-info.module';

@Component({
    selector: 'omt-favorite-list',
    standalone: true,
    imports: [CommonModule, CoreModule, RouterLink, MatDividerModule, FlexModule, NumberRangeKeyModule, TicketTypeModule, AvatarModule, FavoriteButtonComponent, TranslateModule, MissingEntriesInfoModule],
    templateUrl: './favorite-list.component.html',
    styleUrls: ['./favorite-list.component.scss']
})
export class FavoriteListComponent {
    @Input() favorites: FavoriteModel[] = [];
    @Input() hasSearch = false;

    favoriteType = FavoriteType;

    getType(type: FavoriteType): SupportedFavoriteTypes {
        for (const [key, value] of FavoriteTypeMap.entries()) {
            if (value === type) {
                return key;
            }
        }
    }
}
