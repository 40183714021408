<fuse-progress-bar></fuse-progress-bar>

@if (loggedIn) {
  <omt-announcement-host></omt-announcement-host>
}
@switch (fuseConfig.layout.style) {
  @case ('vertical-layout-1') {
    <vertical-layout-1></vertical-layout-1>
  }
  @case ('vertical-layout-2') {
    <vertical-layout-2></vertical-layout-2>
  }
  @case ('vertical-layout-3') {
    <vertical-layout-3></vertical-layout-3>
  }
  @case ('horizontal-layout-1') {
    <horizontal-layout-1></horizontal-layout-1>
  }
}
