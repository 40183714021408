import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ModuleHeaderConfigs} from '@core/constants/module-header-configs';
import {AuthGuard} from '@core/guards/auth.guard';
import {PermissionGuard, PermissionGuardClause} from '@core/guards/permission.guard';

const routes: Routes = [
    {
        path: 'auth',
        loadChildren: () => import('./auth/auth.module').then((x) => x.AuthModule),
        data: {
            headerConfig: ModuleHeaderConfigs.HIDE_HEADER
        }
    },
    {
        path: 'dashboard',
        canActivate: [AuthGuard, PermissionGuard],
        loadChildren: () => import('./dashboard/dashboard.module').then((x) => x.DashboardModule),
        data: {
            headerConfig: ModuleHeaderConfigs.DASHBOARD,
            permission: new PermissionGuardClause('dashboard')
        }
    },
    {
        path: 'ticket',
        canActivate: [AuthGuard, PermissionGuard],
        loadChildren: () => import('./tickets/ticket-v2.module').then((x) => x.TicketV2Module),
        data: {
            headerConfig: ModuleHeaderConfigs.TICKETS,
            permission: new PermissionGuardClause('ticket')
        }
    },
    {
        path: 'active-tickets',
        canActivate: [AuthGuard, PermissionGuard],
        loadChildren: () => import('./boards/components/active-tickets/active-tickets.module').then((x) => x.ActiveTicketsModule),
        data: {
            headerConfig: ModuleHeaderConfigs.TICKETS,
            permission: new PermissionGuardClause('ticketPlayPause')
        }
    },
    {
        path: 'boards',
        canActivate: [AuthGuard, PermissionGuard],
        loadChildren: () => import('./boards/boards.module').then((x) => x.BoardsModule),
        data: {
            headerConfig: ModuleHeaderConfigs.HIDE_HEADER,
            permission: new PermissionGuardClause('ticketBoards')
        }
    },
    {
        path: 'pending-worklogs',
        canActivate: [AuthGuard, PermissionGuard],
        loadChildren: () => import('./tickets/pending-worklogs/pending-worklog.module').then((x) => x.PendingWorklogModule),
        data: {
            headerConfig: ModuleHeaderConfigs.TICKETS,
            permission: new PermissionGuardClause('ticketPlayPause')
        }
    },
    {
        path: 'timetracking',
        canActivate: [AuthGuard],
        loadChildren: () => import('./time-tracking/time-tracking.module').then((x) => x.TimeTrackingModule),
        data: {
            headerConfig: ModuleHeaderConfigs.TIMETRACKING
        }
    },
    {
        path: 'customer',
        canActivate: [AuthGuard, PermissionGuard],
        loadChildren: () => import('./customer/customer.module').then((x) => x.CustomerModule),
        data: {
            headerConfig: ModuleHeaderConfigs.CUSTOMERS,
            permission: new PermissionGuardClause('kunden')
        }
    },
    {
        path: 'human-resource',
        canActivate: [AuthGuard],
        loadChildren: () => import('./human-resource/human-resource.module').then((x) => x.HumanResourceModule),
        data: {
            headerConfig: ModuleHeaderConfigs.HUMANRESOURCES
        }
    },
    {
        path: 'management',
        canActivate: [AuthGuard],
        loadChildren: () => import('./management/management.module').then((x) => x.ManagementModule),
        data: {
            headerConfig: ModuleHeaderConfigs.MANAGEMENT
        }
    },
    {
        path: 'notifications',
        canActivate: [AuthGuard],
        loadChildren: () => import('./notifications/notifications.module').then((x) => x.NotificationsModule),
        data: {
            headerConfig: ModuleHeaderConfigs.NOTIFICATIONS
        }
    },
    {
        path: 'warehouse',
        canActivate: [AuthGuard],
        loadChildren: () => import('./warehouse/warehouse.module').then((x) => x.WarehouseModule),
        data: {
            headerConfig: ModuleHeaderConfigs.WAREHOUSE
        }
    },
    {
        path: 'knowledge-management',
        canActivate: [AuthGuard],
        loadChildren: () => import('./knowledge-management/knowledge-management.module').then((x) => x.KnowledgeManagementModule),
        data: {
            headerConfig: ModuleHeaderConfigs.KNOWLEDGEMANAGEMENT
        }
    },
    {
        path: 'reporting',
        canActivate: [AuthGuard],
        loadChildren: () => import('./reporting/reporting.module').then((x) => x.ReportingModule),
        data: {
            headerConfig: ModuleHeaderConfigs.REPORTING
        }
    },
    {
        path: 'setting',
        canActivate: [AuthGuard],
        loadChildren: () => import('./setting/setting.module').then((x) => x.SettingModule),
        data: {
            headerConfig: ModuleHeaderConfigs.SETTING
        }
    },
    {
        path: 'profile',
        canActivate: [AuthGuard],
        loadChildren: () => import('./profile/profile.module').then((x) => x.ProfileModule)
    },
    {
        path: 'project-management',
        canActivate: [AuthGuard, PermissionGuard],
        loadChildren: () => import('./project-management/project-management.module').then((x) => x.ProjectManagementModule),
        data: {
            headerConfig: ModuleHeaderConfigs.PROJECTS,
            permission: new PermissionGuardClause('projekte')
        }
    },
    {
        path: '',
        redirectTo: '/timetracking/presence',
        pathMatch: 'full'
    },
    {
        path: 'error',
        loadChildren: () => import('./error/error.module').then((x) => x.ErrorModule)
    },
    {
        path: '**',
        canActivate: [AuthGuard],
        loadChildren: () => import('./error/error.module').then((x) => x.ErrorModule)
    }
];

@NgModule({
    declarations: [],
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
