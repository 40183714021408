<div class="navbar-header" [ngClass]="fuseConfig.layout.navbar.secondaryBackground">
  <div class="logo">
    <img class="logo-icon" src="images/logos/fuse.svg"/>
    <span class="logo-text">FUSE</span>
  </div>

  <button mat-icon-button class="toggle-sidebar-folded" (click)="toggleSidebarFolded()" fxHide.lt-lg>
    <mat-icon>menu</mat-icon>
  </button>

  <button mat-icon-button class="toggle-sidebar-opened" (click)="toggleSidebarOpened()" fxHide.gt-md>
    <mat-icon>arrow_back</mat-icon>
  </button>
</div>

<div class="navbar-content" fusePerfectScrollbar [fusePerfectScrollbarOptions]="{suppressScrollX: true}" [ngClass]="fuseConfig.layout.navbar.primaryBackground">
  <fuse-navigation layout="vertical"></fuse-navigation>
</div>
