import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from '@angular/router';
import {AuthenticationService} from '@core/services/global/authentication.service';
import {JwtService} from '@core/services/jwt.service';

@Injectable({providedIn: 'root'})
export class AuthGuard {
    constructor(private readonly router: Router,
                private readonly authService: AuthenticationService,
                private readonly jwtService: JwtService) {
    }

    async canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        const token = this.jwtService.token;
        if (!token || this.jwtService.isTokenExpired(token)) {
            // not logged in so redirect to login page with the return url
            this.jwtService.removeToken();
            this.router.navigate(['/auth/login'], {queryParams: {returnUrl: state.url}}).then();
            return false;
        }

        if (!this.authService.currentUser) {
            const user = this.jwtService.getUserFromToken(token);
            await this.authService.setUser(user);
        }

        return true;
    }
}
