<!-- SIDE PANEL -->
@if (!fuseConfig.layout.sidepanel.hidden && fuseConfig.layout.sidepanel.position === 'left') {
  <!-- PANEL CONTENT -->
}
<!-- / SIDE PANEL -->

<div id="main">
  <!-- TOOLBAR: Above fixed -->
  @if (fuseConfig.layout.toolbar.position === 'above-fixed') {
    <ng-container *ngTemplateOutlet="toolbar"></ng-container>
  }
  <!-- / TOOLBAR: Above fixed -->

  <div id="container-1" class="container" fusePerfectScrollbar [fusePerfectScrollbarOptions]="{suppressScrollX: true, updateOnRouteChange: true}">
    <!-- TOOLBAR: Above static -->
    @if (fuseConfig.layout.toolbar.position === 'above-static') {
      <ng-container *ngTemplateOutlet="toolbar"></ng-container>
    }
    <!-- / TOOLBAR: Above static -->

    <div id="container-2" class="container">
      <!-- NAVBAR: Left -->
      @if (fuseConfig.layout.navbar.position === 'left') {
        <ng-container *ngTemplateOutlet="leftNavbar"></ng-container>
      }
      <!-- / NAVBAR: Left -->

      <!-- CONTENT -->
      <omt-content [ngClass]="{'ml-32': fuseConfig.layout.navbar.position === 'left', 'mr-32': fuseConfig.layout.navbar.position === 'right'}"></omt-content>
      <!-- / CONTENT -->

      <!-- NAVBAR: Right -->
      @if (fuseConfig.layout.navbar.position === 'right') {
        <ng-container *ngTemplateOutlet="rightNavbar"></ng-container>
      }
      <!-- / NAVBAR: Right -->
    </div>

    <!-- FOOTER: Above static -->
    @if (fuseConfig.layout.footer.position === 'above-static') {
      <ng-container *ngTemplateOutlet="footer"></ng-container>
    }
    <!-- FOOTER: Above static -->
  </div>

  <!-- FOOTER: Above fixed -->
  @if (fuseConfig.layout.footer.position === 'above-fixed') {
    <ng-container *ngTemplateOutlet="footer"></ng-container>
  }
  <!-- FOOTER: Above fixed -->
</div>

<!-- SIDE PANEL -->
@if (!fuseConfig.layout.sidepanel.hidden && fuseConfig.layout.sidepanel.position === 'right') {
  <!-- PANEL CONTENT -->
}
<!-- / SIDE PANEL -->

<!-- QUICK PANEL -->
<fuse-sidebar name="quickPanel" position="right" class="quick-panel">
  <quick-panel></quick-panel>
</fuse-sidebar>
<!-- / QUICK PANEL -->

<!-- ----------------------------------------------------------------------------------------------------- -->
<!-- @ PARTIALS                                                                                            -->
<!-- ----------------------------------------------------------------------------------------------------- -->

<!-- TOOLBAR -->
<ng-template #toolbar>
  @if (!fuseConfig.layout.toolbar.hidden) {
    <toolbar
      [ngClass]="
      fuseConfig.layout.toolbar.customBackgroundColor === true
        ? fuseConfig.layout.toolbar.position + ' ' + fuseConfig.layout.toolbar.background
        : fuseConfig.layout.toolbar.position
    "
    >
    </toolbar>
  }
</ng-template>
<!-- / TOOLBAR -->

<!-- FOOTER -->
<ng-template #footer>
  @if (!fuseConfig.layout.footer.hidden) {
    <footer
      [ngClass]="
      fuseConfig.layout.footer.customBackgroundColor === true ? fuseConfig.layout.footer.position + ' ' + fuseConfig.layout.footer.background : fuseConfig.layout.footer.position
    "
    ></footer>
  }
</ng-template>
<!-- / FOOTER -->

<!-- LEFT NAVBAR -->
<ng-template #leftNavbar>
  @if (!fuseConfig.layout.navbar.hidden) {
    <fuse-sidebar name="navbar" class="navbar-fuse-sidebar" [folded]="fuseConfig.layout.navbar.folded" lockedOpen="gt-md">
      <navbar [variant]="fuseConfig.layout.navbar.variant" class="left-navbar"></navbar>
    </fuse-sidebar>
  }
</ng-template>
<!-- / LEFT NAVBAR -->

<!-- RIGHT NAVBAR -->
<ng-template #rightNavbar>
  @if (!fuseConfig.layout.navbar.hidden) {
    <fuse-sidebar name="navbar" position="right" class="navbar-fuse-sidebar" [folded]="fuseConfig.layout.navbar.folded" lockedOpen="gt-md">
      <navbar [variant]="fuseConfig.layout.navbar.variant" class="right-navbar"></navbar>
    </fuse-sidebar>
  }
</ng-template>
<!-- / RIGHT NAVBAR -->
