import {DOCUMENT} from '@angular/common';
import {Component, HostListener, Inject, OnInit} from '@angular/core';
import {AuthenticationService} from '@core/services/global/authentication.service';
import {UtilitiesService} from '@core/services/utilities.service';
import {FuseConfigService} from '@fuse/services/config.service';
import {AppInitializerService} from '@app/app-initializer.service';
import {MobileService} from '@core/services/global/mobile.service';
import {filter} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {FuseSplashScreenService} from '@fuse/services/splash-screen.service';
import {tapOnce} from '@core/utils/custom-operators';
import {FuseConfig} from '@fuse/types';
import {NotificationHttpService} from '@notifications/notification-http.service';
import {Title} from '@angular/platform-browser';

/**
 *  App Component
 */
@Component({
    selector: 'omt-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    fuseConfig: FuseConfig;
    loggedIn = false;

    private readonly defaultTitle = 'OM-Task';

    constructor(
        @Inject(DOCUMENT) private readonly document: any,
        private readonly initializer: AppInitializerService,
        private readonly _fuseConfigService: FuseConfigService,
        private readonly mobileService: MobileService,
        private readonly authService: AuthenticationService,
        private readonly utilitiesService: UtilitiesService,
        private readonly splashScreenService: FuseSplashScreenService,
        private readonly notificationHttpService: NotificationHttpService,
        private readonly title: Title
    ) {
    }

    // used as a global click listener
    @HostListener('document:click', ['$event'])
    documentClick(event: any): void {
        this.utilitiesService.documentClickedTarget.next(event.target);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    setTitle(notificationCount: number): void {
        if (!notificationCount || notificationCount === 0) {
            this.title.setTitle(this.defaultTitle);
            return;
        }

        this.title.setTitle(`${this.defaultTitle} (${notificationCount})`);
    }

    ngOnInit(): void {
        this.splashScreenService.init();

        this.getLoggedIn()
            .pipe((tapOnce(() => this.splashScreenService.hide())))
            .subscribe((loggedIn) => this.loggedIn = loggedIn);

        this.notificationHttpService.unseenNotificationCount.subscribe((count) => this.setTitle(count));

        // Add is-mobile class to the body if the platform is mobile
        if (this.mobileService.mobile) {
            this.document.body.classList.add('is-mobile');
        }

        // Subscribe to config changes
        this._fuseConfigService.config.subscribe((config) => {
            this.fuseConfig = config;

            // Boxed
            if (this.fuseConfig.layout.width === 'boxed') {
                this.document.body.classList.add('boxed');
            } else {
                this.document.body.classList.remove('boxed');
            }

            // Color theme - Use normal for loop for IE11 compatibility

            for (const cssClass of this.document.body.classList) {
                if (cssClass.startsWith('theme-')) {
                    this.document.body.classList.remove(cssClass);
                }

            }

            this.document.body.classList.add(this.fuseConfig.colorTheme);
        });
    }

    private getLoggedIn(): Observable<boolean> {
        return this.authService.isLoggedIn
            .pipe(filter((loggedIn) => loggedIn != null));
    }
}
