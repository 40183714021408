import {Component, ViewEncapsulation} from '@angular/core';
import {FuseSidebarService} from '@fuse/components/sidebar/sidebar.service';

@Component({
    selector: 'quick-panel',
    templateUrl: './quick-panel.component.html',
    styleUrls: ['./quick-panel.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class QuickPanelComponent {
    date = new Date();

    constructor(private readonly fuseSideBarService: FuseSidebarService) {
    }

    closeSelf(): void {
        this.fuseSideBarService.getSidebar('quickPanel').close();
    }
}
