import {CommonModule, registerLocaleData} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import {NgModule} from '@angular/core';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {MatIconModule} from '@angular/material/icon';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {LocaleProvider} from '@app/dynamic-locale-id';
import {ErrorInterceptorProvider} from '@core/interceptors/error.interceptor';
import {JwtInterceptorProvider} from '@core/interceptors/jwt.interceptor';
import {environment} from '@env/environment';
import {FuseModule} from '@fuse/fuse.module';
import {FuseSharedModule} from '@fuse/shared.module';
import {TranslateModule} from '@ngx-translate/core';
import {FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule} from '@fuse/components';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {SharedModule} from '@shared/shared.module';
import {UserSettingsProvider} from '@core/services/user-settings.service';
import {MatLegacySnackBarModule as MatSnackBarModule} from '@angular/material/legacy-snack-bar';
import {ServiceWorkerModule} from '@angular/service-worker';
import {MatNativeDateModule} from '@angular/material/core';
import {DatePickerModule} from '@core/components/datepicker/date-picker.module';
import {AvatarModule} from '@libs/ui/src/components/avatar/avatar.module';
import {MAT_LEGACY_PAGINATOR_DEFAULT_OPTIONS as MAT_PAGINATOR_DEFAULT_OPTIONS} from '@angular/material/legacy-paginator';
import {PaginatorIntlProvider} from '../i18n/paginator-intl';
import {AppInitializerProvider} from '@app/app-initializer.service';
import {WindowRefService} from '@core/services/global/window-ref.service';
import {ArticleSupplierConditionsCardComponent} from '@warehouse/master-data/article/shared/article-supplier-conditions-card/article-supplier-conditions-card.component';
import {UserGeneralInfoCardComponent} from '@core/components/user-general-info-card/user-general-info-card.component';
import {UserGeneralInfoCardContainerComponent} from '@core/components/user-general-info-card/user-general-info-card-container.component';
import {fuseConfig} from '@core/constants/fuse-config';
import {LayoutModule} from '@core/layouts/layout.module';
import {ContentModule} from '@core/layouts/components/content/content.module';


@NgModule({
    declarations: [AppComponent],
    imports: [
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        AppRoutingModule,

        TranslateModule.forRoot(),
        DatePickerModule.forRoot(),

        // Material date module
        MatNativeDateModule,

        // Material
        MatSnackBarModule,
        MatButtonModule,
        MatIconModule,

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,

        // App modules
        LayoutModule,
        ContentModule,
        SharedModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production,
            // Register the ServiceWorker as soon as the app is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000'
        }),
        AvatarModule.forRoot(environment)
    ],
    providers: [
        AppInitializerProvider,
        UserSettingsProvider,
        JwtInterceptorProvider,
        ErrorInterceptorProvider,
        {provide: MAT_PAGINATOR_DEFAULT_OPTIONS, useValue: {formFieldAppearance: 'outline'}},
        PaginatorIntlProvider,
        LocaleProvider,
        WindowRefService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    // TODO - Fix this properly
    // this is needed right now, as some components, which are not explicitly called in any template, get removed from the final production
    // bundle. An explicit reference here prevents this
    decoratedComponents = [ArticleSupplierConditionsCardComponent, UserGeneralInfoCardComponent, UserGeneralInfoCardContainerComponent];

    constructor() {
        registerLocaleData(localeDe, 'de');
    }
}
